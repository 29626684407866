<template>
  <b-modal
    :id="modalId"
    v-model="isVisible"
    :centered="centered"
    :scrollable="scrollable"
    :size="size"
    :hide-footer="hideFooter"
  >
    <template #modal-title>
      <slot name="modalHeader"/>
    </template>
    <slot name="modalBody"/>
    <template #modal-footer>
      <slot name="modalFooter"/>
    </template>
  </b-modal>
</template>

<script>
export default {
  name: 'MediaModal',
  props: {
    size: {
      default: 'modal-lg',
      type: String
    },
    visible: {
      default: false,
      type: Boolean
    },
    centered: {
      default: false,
      type: Boolean
    },
    hideFooter: {
      default: false,
      type: Boolean
    },
    scrollable: {
      default: false,
      type: Boolean
    },
    modalId: {
      required: false,
      default: null,
      type: String
    }
  },

  data() {
    return {
      isVisible: false
    }
  },

  watch: {
    visible() {
      this.isVisible = this.$props.visible
    }
  },

  mounted() {
    this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
      if (modalId === this.$props.modalId) {
        this.$emit('media-modal-close')
      }
    })

    document.addEventListener('keydown', e => {
      if (this.show && e.keyCode === 27) {
        this.close()
      }
    })
  }
}
</script>
