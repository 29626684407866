<template>
  <media-modal
    :visible="show"
    :centered="true"
    size="default"
  >
    <template slot="modalHeader">
      New folder
    </template>
    <template slot="modalBody">
      <div
        v-if="loading"
        class="text-center">
        <span class="spinner icon-spinner2"/>Loading...
      </div>

      <div v-else>
        <div class="modal-body">
          <div class="form-group fg-line">
            <label>Folder name</label>
            <input
              ref="folderName"
              v-model="newFolderName"
              type="text"
              class="form-control"
              @keyup.enter="createFolder()"
            >
          </div>

          <media-errors :errors="errors"/>

        </div>
      </div>
    </template>

    <template slot="modalFooter">
      <div class="w-100">
        <div class="float-right">
          <b-button
            variant="primary"
            size="sm"
            class="mr-1"
            @click="createFolder()"
          >
            Apply
          </b-button>
          <b-button
            variant="secondary"
            size="sm"
            @click="close()"
          >
            Cancel
          </b-button>
        </div>
      </div>
    </template>
  </media-modal>
</template>

<script>
import { nextTick } from '@vue/composition-api'
import axiosIns from '@/libs/axios'
import fileManagerMixin from '@/modules/mixins/fileManager'
import MediaModal from '@/views/media-manager/MediaModal.vue'
import MediaErrors from './MediaErrors.vue'

export default {
  components: {
    MediaModal,
    'media-errors': MediaErrors
  },

  mixins: [fileManagerMixin],

  props: {
    currentPath: {
      default: '',
      type: String
    },

    /**
         * Default route prefix
         */
    prefix: {
      default: '/',
      type: String
    },

    show: {
      default: false,
      type: Boolean
    }
  },

  data() {
    return {
      errors: [],
      loading: false,
      newFolderName: null,
      size: 'modal-md'
    }
  },

  watch: {
    show(val) {
      if (val) {
        nextTick(() => {
          this.$refs.folderName.focus()
        })
      }
    }
  },

  mounted() {
    document.addEventListener('keydown', e => {
      if (this.show && e.keyCode === 13) {
        this.createFolder()
      }
    })
  },

  methods: {
    close() {
      this.newFolderName = null
      this.loading = false
      this.errors = []
      this.$emit('media-modal-close')
    },

    createFolder() {
      if (!this.newFolderName) {
        this.errors = ['Please provide a name for the new folder']
        return
      }

      const data = {
        folder: this.currentPath,
        new_folder: this.newFolderName
      }

      this.loading = true
      axiosIns.post(`${this.prefix}browser/folder`, data).then(
        response => {
          this.mediaManagerNotify(response.data.success)
          this.$emit('reload-folder')
          this.close()
        },
        errors => {
          this.errors = (errors.response.data.error) ? errors.response.data.error : errors.response.statusText
          this.loading = false
        }
      )
    }
  }
}
</script>
